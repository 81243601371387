import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AuthProvider from "./Pages/Auth/AuthContext";
import VetApp from "./VetApp";
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CacheBuster from './CacheBuster';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#2AB1C3"
        }
    }
});

ReactDOM.render(

    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
              console.log("refresh");
            refreshCacheAndReload();
          }

          return (
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <VetApp />
                </ThemeProvider>
            </AuthProvider>
          );
        }}
    </CacheBuster>,

    // <AuthProvider>
    //     <ThemeProvider theme={theme}>
    //         <VetApp />
    //     </ThemeProvider>
    // </AuthProvider>,
    document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();