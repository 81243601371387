import Pet from "../Assets/pet_pic_placeholder.png";
import Parent from "../Assets/parent_pic_placeholder.png";
import Vet from "../Assets/vet_pic_placeholder.png";

export const resolveToDefaultPhoto = (photo_url, account_type) => {
    if (photo_url && photo_url !== "") {
        return photo_url;
    }
    
    switch(account_type) {
        case "pet":
            return Pet;
        case "parent":
            return Parent;
        case "vet":
            return Vet;
    }
} 