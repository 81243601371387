import React from "react";
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

class EarningsCard extends React.Component {
  render() {
    return (
      <div>
        <Paper 
            elevation={3} 
            style={{ 
                borderRadius: "6px", 
                marginBottom: "20px", 
                marginLeft: "1%", 
                width: "300px",
                marginBottom: "20px",
                paddingTop: "10px",
                paddingLeft: "2%",
                paddingBottom: "20px",
                paddingRight: "3%"
        }}>
            <span style={{fontSize: "44px"}} >23</span><br/>
            <span style={{fontWeight: "300"}}>Total Cases</span> <br/><br/>
            <LinearProgress variant="determinate" value={74} /> <br />
            <div style={{ display:"flex", fontWeight: "300"}}><div style={{ backgroundColor: "#2AB1C3", width:"20px", height:"20px", borderRadius: "2px", marginRight: "5px"}}></div>Active Consults (17)</div><br />
            <div style={{ display:"flex", fontWeight: "300"}}><div style={{ backgroundColor: "rgb(174, 225, 232)", width:"20px", height:"20px", borderRadius: "2px", marginRight: "5px"}}></div>Completed Consults (6)</div><br />
        </Paper>
        <Paper 
            elevation={3} 
            style={{ 
                borderRadius: "6px", 
                marginBottom: "20px", 
                marginLeft: "1%", 
                width: "300px", 
                marginBottom: "20px",
                paddingTop: "10px",
                paddingLeft: "2%",
                paddingBottom: "20px",
                paddingRight: "3%"
        }}>
            <span style={{fontSize: "44px"}} >₹ 23,500</span><br/>
            <span style={{fontWeight: "300"}}>Total earnings as on 2 Mar</span>
        </Paper>
      </div>
    )
  }
}

export default EarningsCard;