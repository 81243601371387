import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Logo from "../../Assets/logo.svg";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import { signOut } from "../../firebase/firebase";
import { AuthContext } from "../../Pages/Auth/AuthContext";
import { db } from "../../firebase/firebase";
import Dialog from '@material-ui/core/Dialog';
import LogoutImage from "../../Assets/logout.png";
import styles from "./NavBar.module.css";
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { resolveToDefaultPhoto } from "../../utils/resolveToDefaultPhoto";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: "auto",
  },
  title: {
    flexGrow: 1,
  },
  appbar: {
    background: "white",
    color: "black"
  }
}));

export default function NavBar({sidebarOpen, setSidebarOpen}) {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [vet, setVet] = useState("Loading...");
  const [drawer, setDrawer] = useState(false);
  const { phoneNumber } = useContext(AuthContext);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
      setOpenDialog(true);
  };

  const handleCloseDialog = () => {
      setOpenDialog(false);
  };

  useEffect(() => {
    db.collection("vets").doc(phoneNumber).get({source:"server"}).then((doc) => {
      if (doc.exists) {
        setVet({
          name: doc.data().name,
          photo: doc.data().photo
        });
      }}).catch((error) => {
      console.log("Error getting document:", error);
    });
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openDrawer = () => {
    setDrawer(true);
  }

  const closeDrawer = () => {
    setDrawer(false);
  }

  const handleClose = (link) => {
    setAnchorEl(null);
    switch(link) {
      case "profile":
        history.push("/vet");
        break;
      case "logout":
        handleClickOpenDialog();
        break;
    }
  };

  const handleSideBarClick = () => {
    setSidebarOpen(!sidebarOpen);
  }

   const renderCategory = () => {
     switch(location.pathname) {
      case "/completed":
        return "Completed Consults";
      case "/vet":
        return "Doctor Profile";
      case "/pet":
        return "Pet Profile";
      case "/session":
        return "Consult Details";
      case "/active":
      case "/":
        return "Active Consults";
     }
   }

  return (
    <>
      <AppBar position="static" className={classes.appbar}>
          <div className={styles.appbar_container}>
            <div className={styles.sidemenu_icon}>
            {
              sidebarOpen?
              (<MenuOpenIcon onClick={handleSideBarClick} />):
              (<MenuIcon onClick={handleSideBarClick} />)
            }
            </div>
              <Button className={styles.logo_icon} onClick={() => history.push("/")}>
                <img src={Logo}  />
              </Button>
              <div className={styles.page_title}>
                {renderCategory()}
              </div>
              <div className={styles.control_container}>
                {/* <Button onClick={openDrawer}>
                  <Badge badgeContent={0} color="secondary" >
                    <NotificationsNoneOutlinedIcon />
                  </Badge>
                </Button> */}

                <Divider orientation="vertical" flexItem style={{ marginRight: "10px" }} />

                <Button onClick={handleClick}>
                  <div style={{ display:"flex", alignItems:"center", fontWeight:"bold" }}>
                    <img className={styles.vet_photo} src={resolveToDefaultPhoto(vet.photo, "vet")} />
                    <span className={styles.vet_name}>{vet.name}</span>
                  </div>
                </Button>
              </div>
          </div>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("profile")}>Profile</MenuItem>
        <MenuItem onClick={() => handleClose("logout")}>Log out</MenuItem>
      </Menu>
      <Drawer anchor={"right"} open={drawer}>
        <div style={{ paddingTop:"30px", paddingLeft:"40px", paddingRight:"500px" }} >
          <div style={{ display:"inline-flex" }} >
            <Button  onClick={closeDrawer}><ArrowBackIcon /> </Button> &emsp;
            <div style={{alignSelf:"center", fontWeight:"bold"}}>
              Notifications
            </div>
          </div>
        </div>
      </Drawer>
      <Dialog onClose={handleCloseDialog} aria-labelledby="simple-dialog-title" open={openDialog}>
            <div className={styles.popup_container}>
                <img src={LogoutImage} className={styles.logout_img}/>
                <div className={styles.text}>
                  Are you sure you want to logout? <br/><br/>
                </div>
                Don’t worry, you won’t lose your data 😉
                <div className={styles.button_container}>
                    <div className={styles.button_style_no} onClick={handleCloseDialog}>
                        No
                    </div>
                    <div className={styles.button_style} onClick={signOut}>
                        Yes
                    </div>
                </div>
            </div>
        </Dialog>
    </>
  );
}
