import React, { useState, useEffect } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import one from '../../Assets/images/1.jpeg';
import two from '../../Assets/images/2.jpeg';
import three from '../../Assets/images/3.jpeg';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import styles from "./PetProfile.module.css";
import { db, storageRef } from "../../firebase/firebase";
import { getAge } from "../../utils/getAge";
import { resolveToDefaultPhoto } from "../../utils/resolveToDefaultPhoto";

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: "100%",
      width: '100%',
      backgroundColor: 'blue',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: 'black',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  tableContainer: {
    width: "100%",
  },
}));

function createData(title, value) {
  return { title, value };
}



export default function PetProfile(props) {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [petProfile, setPetProfile] = useState(null);
  const [petData, setPetData] = useState({});
  const [petRecords, setPetRecords] = useState({});
  const [pastConsults, setPastConsults] = useState({});

  const rows = [
    createData('Breed', petData.breed),
    createData('Neutered', petData.neutered),
    createData('Last vaccination date', petData.last_vaccination),
    createData("Last deworming", petData.last_deworming),
    createData("Current diet", petData.current_diet),
    createData("Past medical issues", petData.past_medical_issues),
    createData("Allergies", petData.allergies)
  ];

  useEffect(() => {
    db.collection("users")
      .doc(props.location.state.userId)
      .collection("pets")
      .doc(props.location.state.petId)
      .get()
      .then((doc) => {
        setPetData(doc.data());
      });
  }, []);

  const fetchPetRecords = async () => {
    var records = [];
    await db.collection("users")
    .doc(props.location.state.userId)
    .collection("pets")
    .doc(props.location.state.petId)
    .collection("records")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        records.push(doc.data());
      });
    });
    setPetRecords(records);
  };

  const fetchPastConsults = async () => {
    var consults = [];
    await db.collection("consults")
    .where("pet_id", "==", props.location.state.petId)
    .where("status", "in", ["COMPLETED", "FOLLOW UP PERIOD"])
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        consults.push({
          id: doc.id,
          data: doc.data()
        });
      });
    });
    setPastConsults(consults);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch(newValue) {
      case 1:
        fetchPetRecords();
        break;
      case 2:
        fetchPastConsults();
        break;
    }
  };

  const renderHealthRecords = () => {
    var health_records = [];
    Object.values(petRecords).forEach((record) => {
      health_records.push(
        <Paper elevation={3} className={styles.health_paper} onClick={() => showVaccinationDetails(record)}>
          <div className={styles.health_paper_container}>
            <div className={styles.image_container}>
            <img src={resolveToDefaultPhoto(props.location.state.petPhoto, "pet")} className={styles.health_image}/>
          </div>
            <div className={styles.health_data_container}>
              <span style={{fontWeight:"bold"}}>{record.title}</span><br/>
              <span className={styles.health_data_time}>Updated on {record.updated_at?.toDate().toLocaleString("en-IN", {
                                  weekday: "short",
                                  day:    "numeric",
                                  month:  "short",
                                  year:   "numeric",
                                  hour:   "2-digit",
                                  minute: "2-digit",
                                  timeZone: "Asia/Kolkata"
                              })}</span>
            </div>
          </div>
        </Paper>
      );
    });
    return health_records;
  }

  const renderPastConsults = () => {
    var past_consults = [];
    Object.values(pastConsults).forEach((consult) => {
      past_consults.push(
        <Paper
          elevation={3}
          className={styles.past_session_paper}
          onClick={() => {
            history.push({
              pathname: "/session",
              state: {
                  caseData: consult,
                  isPastConsult: true
              }
              });
          }}
          >
          <div className={styles.past_session_paper_container}>
            <div className={styles.past_session_parent_image_container}>
              <img src={resolveToDefaultPhoto(consult.data.user_photo, "parent")} className={styles.past_session_parent_image}/>
            </div>
            <div className={styles.past_session_data_container}>
              <span style={{fontWeight:"bold"}}>{consult.data.vet_name}</span><br/>
              <span className={styles.past_session_data_span}>
                Completed on {consult.data.ended_at?.toDate?.().toLocaleString("en-IN", {
                                    weekday: "short",
                                    day:    "numeric",
                                    month:  "short",
                                    year:   "numeric",
                                    hour:   "2-digit",
                                    minute: "2-digit",
                                    timeZone: "Asia/Kolkata"
                                })} <br/>
                Issue: {consult.data.issue_type}
              </span>
            </div>
          </div>
        </Paper>
      );
    });
    return past_consults;
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tileData = [
    {
      img: one,
      title: 'Image',
      author: 'author',
    },
    {
      img: two,
      title: 'Image',
      author: 'author',
    },
    {
      img: three,
      title: 'Image',
      author: 'author',
    },
  ];

  const showVaccinationDetails = (vaccinationDetails) => {
    setPetProfile(vaccinationDetails);
  };

  const showPetProfile = () => {
    setPetProfile(null);
  };

    return (
      <>
        {
          !petProfile ?
          (<div className={styles.main_container}>
            <div className={styles.back_button_container}>
              <Button onClick={() => history.goBack()} className={styles.back_button}>
                <div className={styles.back_button_div} >
                  <ArrowBackIcon />  &emsp; Go back to Sessions
                </div>
              </Button>
            </div>
            <div className={styles.details_container} >
              <div className={styles.name_card} >
                <Paper elevation={3} className={styles.paper}>
                  <div className={styles.pet_details_container}>
                    <div className={styles.pet_container}>
                        <img src={resolveToDefaultPhoto(props.location.state.petPhoto, "pet")} className={styles.pet_image} />
                          <span className={styles.pet_name}>
                          {petData.name}
                          </span>
                    </div>
                    <div className={styles.parent_container}>
                        <div className={styles.pet_name_container}>
                          Parent
                        </div>
                        <div>
                            <img src={resolveToDefaultPhoto(props.location.state.userPhoto, "parent")} className={styles.parent_image}/>
                        </div>
                        <div style={{fontWeight:"bold"}}>
                          {props.location.state.userName}
                        </div>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className={styles.data_container} >
                <Paper elevation={3} className={styles.data_paper}>
                  <div>
                    <AppBar position="static">
                      <StyledTabs value={value} onChange={handleChange} centered variant="fullWidth" className={styles.styled_tabs} >
                        <StyledTab label="Description" style={{fontWeight:"bold"}}/>
                        <StyledTab label="Health Records" style={{fontWeight:"bold"}}/>
                        <StyledTab label="Past Consults" style={{fontWeight:"bold"}}/>
                      </StyledTabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                      <div className={styles.pet_data_container}>
                          <span>{getAge(petData.date_of_birth)}</span>
                          <Divider orientation="vertical" flexItem />
                          <span>{petData.weight} kg</span>
                          <Divider orientation="vertical" flexItem />
                          <span>{petData.species}</span>
                          <Divider orientation="vertical" flexItem />
                          <span>{petData.gender}</span>
                      </div>
                      <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table size="medium">
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow key={row.title}>
                                <TableCell component="th" scope="row">
                                  {row.title}
                                </TableCell>
                                <TableCell align="right" style={{fontWidth:"bold"}}>{row.value}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {renderHealthRecords()}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {renderPastConsults()}
                    </TabPanel>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
          ) : (
            <div className={styles.main_container}>
            <div className={styles.back_button_container}>
              <Button onClick={showPetProfile} className={styles.back_button}>
                <div className={styles.back_button_div} >
                  <ArrowBackIcon />  &emsp; Go back to Pet Details
                </div>
              </Button>
            </div>
            <div className={styles.details_container} >
              <div className={styles.name_card} >
                <Paper elevation={3} className={styles.paper}>
                  <div className={styles.pet_container}>
                      <img src={resolveToDefaultPhoto(props.location.state.petPhoto, "pet")} className={styles.pet_image} />
                        <span className={styles.pet_name}>
                        {petData.name}
                        </span>
                      <div className={styles.pet_name_container}>
                        <span className={styles.name}>
                        Parent
                        </span>
                      </div>
                  </div>
                  <div className={styles.parent_container}>
                          <img src={resolveToDefaultPhoto(props.location.state.userPhoto, "parent")} className={styles.parent_image}/>
                      <div>
                        {props.location.state.userName}
                      </div>
                  </div>
                </Paper>
              </div>
              <div className={styles.data_container} >
                <Paper elevation={3} className={styles.data_paper}>
                  <div className={styles.vaccination_details_container}>
                    <img src={petProfile.file} className={styles.vaccination_details_vaccination_report}/>
                    <div className={styles.vaccination_details_vaccination_report_title}>
                      <span className={styles.vaccination_details_vaccination_report_title_title}>
                        Title
                      </span>
                      <span className={styles.vaccination_details_vaccination_report_title_text}>
                        {petProfile.title}
                        </span>
                    </div>
                    <Divider />
                    <div className={styles.vaccination_details_vaccination_report_description}>
                      <span className={styles.vaccination_details_vaccination_report_description_title}>
                        Description
                      </span>
                      <span className={styles.vaccination_details_vaccination_report_description_text}>
                        {petProfile.description}
                      </span>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
          )
        }
      </>
    )
}
