import React, { useState, useEffect, useContext } from "react";
import CaseList from "./CaseList";
import SideBar from "./SideBar/SideBar";
import EarningsCard from "./EarningsCard/EarningsCard";
import { useLocation } from 'react-router-dom';
import styles from "./Home.module.css";
import { db } from "../../firebase/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from "../../Pages/Auth/AuthContext";

export default function Home ({sidebarOpen, setSidebarOpen}) {
  const location = useLocation();
  const [consults, setConsults] = useState([]);
  const [loader, setLoader] = useState(false);
  const { phoneNumber } = useContext(AuthContext);

  const mapCaseListTypeToStatus = (type) => {
    switch(type) {
      case "/completed":
        return [
          "COMPLETED"
        ];
      case "/active":
        return [
          "IN PROGRESS",
          "ONGOING",
          "FOLLOW UP PERIOD"
        ];
      default:
        return [
          "IN PROGRESS",
          "ONGOING",
          "FOLLOW UP PERIOD"
        ];
    }
  }

   useEffect(() => {
    setConsults({});
    setLoader(true);
    let statusArray = mapCaseListTypeToStatus(location.pathname);

    if (statusArray) {
      let consults_array = [];
      let consultsFetchPromises = [];
      
      statusArray.forEach(status => {
        consultsFetchPromises.push(
          db.collection("consults")
          .where("vet_id", "==", phoneNumber)
          .where("status", "==", status)
          .orderBy("created_at", "desc")
          .get()
        );
      });

      Promise.all(consultsFetchPromises).then((snapshots) => {
        snapshots.forEach(snap => {
          snap.forEach(doc => {
            consults_array.push({
              id: doc.id,
              data: doc.data()
            })
          });
        });

        setConsults(consults_array);
        setLoader(false);
      });
    }
   }, [location.pathname]);

   const renderCaseList = () => {
      switch(location.pathname) {
        case "/earnings":
          return <EarningsCard />;
          break;
        case "/completed":
          return <CaseList consults={consults} type="completed"/>;
          break;
        case "/active":
          return <CaseList consults={consults} type="active"/>;
          break;
        // case "/requests":
        //   return <CaseList consults={consults} type="requests"/>;
        //   break;
        default:
          return <CaseList consults={consults} type="active"/>;
      }
   }


    return (
        <div className={styles.main_container}>
          <div className={styles.sidebar_container}>
            <div className={sidebarOpen? `${styles.sidebar} ${styles.active}` : `${styles.sidebar}`}>
              <SideBar setSidebarOpen={setSidebarOpen}/>
            </div>
          </div>
          <div className={styles.caselist_section} >
            {
              loader?
              (
                <CircularProgress className={styles.progress_circle}/>
              ) : (
                renderCaseList()  
              )
            }
          </div>
        </div>
    )
}