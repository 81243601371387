import { React, useContext, useState, useEffect } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { db } from "../../firebase/firebase";
import { AuthContext } from "../Auth/AuthContext";
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "./VetProfile.module.css";
import { resolveToDefaultPhoto } from "../../utils/resolveToDefaultPhoto";

export default function VetProfile() {
  const history = useHistory();
  const [vetData, setVetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { phoneNumber } = useContext(AuthContext);

  useEffect(() => {
    db.collection("vets").doc(phoneNumber).get().then((doc) => {
      if (doc.exists) {
        setVetData(doc.data());
        setLoading(false);
      }}).catch((error) => {
      console.log("Error getting document:", error);
    });
  }, [])

  return (
    <>
      { loading ? (
        <div className={styles.loader}>
          <CircularProgress className={styles.progress_circle}/>
        </div>
        ) : (
          <div className={styles.main_container}>
          <Button onClick={() => history.push("/")}>
            <div className={styles.arrow_button_container} >
              <ArrowBackIcon />  &emsp; Home
            </div>
          </Button>
          <div >
          <Paper elevation={3} className={styles.details_paper}>
            <div className={styles.paper_container}>

                <div className={styles.profile_pic_container}>
                  <img className={styles.profile_pic} src={resolveToDefaultPhoto(vetData.photo, "vet")} />
                </div>
                <div className={styles.details_grid_container}>

                    <div>
                      <div className={styles.details_name}>
                        Name
                      </div>
                      <div className={styles.details_value}>
                        {vetData.name}
                      </div>
                    </div>

                    <div>
                    <div className={styles.details_name}>
                        Email ID
                      </div>
                      <div className={styles.details_value}>
                        {vetData.email}
                      </div>
                    </div>

                    <div>
                    <div className={styles.details_name}>
                        Phone no.
                      </div>
                      <div className={styles.details_value}>
                        {phoneNumber}
                      </div>
                    </div>


                    <div>
                    <div className={styles.details_name}>
                        City
                      </div>
                      <div className={styles.details_value}>
                      {vetData.city}
                      </div>
                    </div>

                  {/*}  <div>
                    <div className={styles.details_name}>
                        Clinic
                      </div>
                      <div className={styles.details_value}>
                      {vetData.clinic}
                      </div>
                    </div> */}


                    <div>
                    <div className={styles.details_name}>
                        License Number
                      </div>
                      <div className={styles.details_value}>
                        {vetData.license}
                      </div>
                    </div>

                    <div>
                    <div className={styles.details_name}>
                        Registered With
                      </div>
                      <div className={styles.details_value}>
                      {vetData.state_of_registration}
                      </div>
                    </div>

                   {/* <div className={styles.divider} >
                      <Divider style={{width: "90%"}}/>
                    </div> */}

                    <div className={styles.grid_last_row}>
                    <div className={styles.details_name}>
                        Bio
                      </div>
                      <div className={styles.details_value}>
                      {vetData.bio}
                      </div>
                    </div>
                </div>
            </div>
                <br />
          </Paper>
          </div>
        </div>
        )}</>
    )
}
