import React, { useState } from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { signOut } from "../../../firebase/firebase";
import Dialog from '@material-ui/core/Dialog';
import LogoutImage from "../../../Assets/logout.png";
import styles from "./SideBar.module.css";
import { isMobile } from 'react-device-detect';

export default function SideBar ({setSidebarOpen}) {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
      setOpenDialog(true);
  };

  const handleCloseDialog = () => {
      setOpenDialog(false);
  };

  const handleListItemClick = (list_item) => {
    if(isMobile) {
      setSidebarOpen(false);
    }
    switch (list_item) {
      case "active":
        history.push("/active")
        break;
      case "completed":
        history.push("/completed")
        break;
      case "earnings":
        history.push("/earnings")
        break;
    }
  }

  const selectedFlag = (list_item) => {
    switch (window.location.pathname) {
      case "/completed":
        if (list_item == "completed") {
          return true;
        }
        break;
      default:
        if (list_item == "active") {
          return true;
        }

    }
  }

    return (
      <>
      <div className={styles.main_container}>
        <List>
          <div className={styles.list_container}>
          <ListItem button onClick={() => handleListItemClick("active")} selected={selectedFlag("active")}>
            <div className={styles.list_item}>
              <ErrorIcon style={{ marginRight: "5%" }} />
              <ListItemText primary="Active" />
            </div>
          </ListItem>
          <ListItem button onClick={() => handleListItemClick("completed")} selected={selectedFlag("completed")}>
            <div className={styles.list_item}>
              <CheckCircleOutlineIcon style={{ marginRight: "10px" }} />
              <ListItemText primary="Completed"/>
            </div>
          </ListItem>
          </div>
        </List>
        {/* <Divider /> */}
        <List>
        <div className={styles.try}>
          {/* <ListItem button onClick={() => handleListItemClick("earnings")} selected={selectedFlag("earnings")}>
            <div className={styles.list_item}>
            <TrendingUpIcon style={{ marginRight: "10px" }} />
            <ListItemText primary="Earnings"/>
            </div>
          </ListItem> */}
          {/* <ListItem button onClick={()=> window.open("https://www.wagr.in/blog/", "_blank")}>
          <div className={styles.list_item}>
            <ImportContactsIcon style={{ marginRight: "5%" }} />
            <ListItemText primary="The Wagr Blog"/>
          </div>
          </ListItem> */}
          </div>
        </List>
        <div className={styles.footer}>
          <div className={styles.sign_out_button}>
          <Button variant="outlined" color="inherit" onClick={handleClickOpenDialog}>
              Sign out
          </Button>
          </div>
          vet.wagr.ai - v0.7
        </div>
      </div>
      <Dialog onClose={handleCloseDialog} aria-labelledby="simple-dialog-title" open={openDialog}>
            <div className={styles.popup_container}>
                <img src={LogoutImage} className={styles.logout_img}/>
                <div className={styles.text}>
                  Are you sure you want to logout? <br/><br/>
                </div>
                Don’t worry, you won’t lose your data 😉
                <div className={styles.button_container}>
                    <div className={styles.button_style_no} onClick={handleCloseDialog}>
                        No
                    </div>
                    <div className={styles.button_style} onClick={signOut}>
                        Yes
                    </div>
                </div>
            </div>
        </Dialog>
      </>
    )
}
