import React from "react";
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import { resolveToDefaultPhoto } from "../../../utils/resolveToDefaultPhoto";
import styles from "./CompletedCaseCard.module.css";

function CompletedCaseCard({ caseData }) {
    const history = useHistory();

    return (
        <div
            className={styles.main_container}
            onClick={() => history.push({
                pathname: "/session",
                state: {
                    caseData: caseData,
                    isCompleted: true
                }
                })}
        >
        <Paper elevation={3}>
            <div className={styles.case_card}>

                <div className={styles.photo_container}>
                    <img src={resolveToDefaultPhoto(caseData['data'].pet_photo, "pet")} className={styles.pet_photo} />
                    <img src={resolveToDefaultPhoto(caseData['data'].user_photo, "parent")} className={styles.parent_photo} />
                </div>

                <div className={styles.data_div} >
                    <div  className={styles.name_div}>
                        {caseData['data'].pet_name}, {caseData['data'].user_name}
                    </div>
                    <div className={styles.time_div} >
                      {/*  Completed on {caseData['data'].ended_at?.toDate?.().toISOString().substr(0,10)} {caseData['data'].ended_at?.toDate?.().toISOString().substr(11,5)} */}
                        Completed on {caseData['data'].ended_at?.toDate?.().toLocaleString("en-IN", {
                                              weekday: "short",
                                              day:    "numeric",
                                              month:  "short",
                                              year:   "numeric",
                                              hour:   "2-digit",
                                              minute: "2-digit",
                                              timeZone: "Asia/Kolkata"
                                          })}
                    </div>
                    <div>
                        Issue: {caseData['data'].issue_type}
                    </div>
                </div>

            </div>
        </Paper>
        </div>
    )
}

export default CompletedCaseCard;
