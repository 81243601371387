import firebase from "firebase";
import firebaseConfig from "../.env.js";

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storageRef = firebase.storage().ref();

export const signOut = () => {
  auth.signOut().then(function() {
    window.location.reload();
    console.log('Signed Out');
  }, function(error) {
    console.error('Sign Out Error', error);
  });
}