import React, { useState, useRef, useEffect, useContext } from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputButton from "../../Assets/prescription_input_button.png";
import { makeStyles } from '@material-ui/core/styles';
import styles from "./SessionDetails.module.css";
import { useHistory } from "react-router-dom";
import { db, storageRef } from "../../firebase/firebase";
import firebase from "firebase";
import EditIcon from '@material-ui/icons/Edit';
import { AuthContext } from "../../Pages/Auth/AuthContext";
import VideocamIcon from '@material-ui/icons/Videocam';
import LinkIcon from '@material-ui/icons/Link';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { getAge } from "../../utils/getAge";
import Checkbox from '@material-ui/core/Checkbox';
import EmergencyBulb from "../../Assets/emergency_bulb.png";
import { resolveToDefaultPhoto } from "../../utils/resolveToDefaultPhoto";
import { statusColor } from "../../utils/statusColor";
import { checkImage } from "../../utils/checkImage";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: "100%",
      width: '100%',
      backgroundColor: 'blue',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: 'black',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index} = props;

  return (
    <>
      {value === index && (
          <Box p={3}>
            {children}
          </Box>
      )}
    </>
  );
}

export default function SessionDetails(props) {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const inputRef = useRef();
  const image_viewerRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [sessionData, setSessionData] = useState({});
  const [allowVideo, setAllowVideo] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [dialogState, setDialogState] = useState({});
  const { phoneNumber } = useContext(AuthContext);
  const [videoCallData, setVideoCallData] = useState({});
  const [petDetails, setPetDetails] = useState({});
  const [petDetailsEmergencyCheckBox, setPetDetailsEmergencyCheckBox] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const [markCompletedDialogOpen, setMarkCompletedDialogOpen] = useState(false);


  const handleClickOpen = () => {
    setPetDetailsEmergencyCheckBox(sessionData.emergency);
    setOpen(true);
    if (sessionData.suggested_followup_date && sessionData.suggested_followup_date != "") {
      dialogState["suggested_followup_date"] = new Date(sessionData.suggested_followup_date?.toDate?.());
      setDialogState(dialogState)
    }

  };

  const markCompletedDialogHandleOpen = () => {
    setMarkCompletedDialogOpen(true);
  }

  const markCompletedDialogHandleClose = () => {
    setMarkCompletedDialogOpen(false);
  }

  const handleClose = () => {
    setPreviewImages([]);
    setOpen(false);
  }

  const handleSave = async () => {
    setOpen(false);
    setUploadLoader(true);
    let temp_sd = sessionData;
    if(dialogState.hasOwnProperty("diagnosis")) {
      db.collection("consults").doc(props.location.state.caseData.id).collection("consult_details")
      .doc("details_document").set({
        diagnosis: dialogState['diagnosis'],
      }, {merge: true});

      temp_sd['diagnosis'] = dialogState['diagnosis'];
    }

    if(dialogState.hasOwnProperty("emergency")) {
      db.collection("consults").doc(props.location.state.caseData.id).collection("consult_details")
      .doc("details_document").set({
        emergency: dialogState['emergency'],
      }, {merge: true});

      temp_sd['emergency'] = dialogState['emergency'];
    }

    if(dialogState.hasOwnProperty("recommendations")) {
      db.collection("consults").doc(props.location.state.caseData.id).collection("consult_details")
      .doc("details_document").set({
        recommendations: dialogState['recommendations'],
      }, {merge: true});

      temp_sd['recommendations'] = dialogState['recommendations'];
    }

    if(dialogState.hasOwnProperty("prescription_text")) {
      db.collection("consults").doc(props.location.state.caseData.id).collection("consult_details")
      .doc("details_document").set({
        prescription_text: dialogState['prescription_text'],
      }, {merge: true});

      temp_sd['prescription_text'] = dialogState['prescription_text'];
    }

    if(dialogState.hasOwnProperty("suggested_followup_date")) {
      db.collection("consults").doc(props.location.state.caseData.id).collection("consult_details")
      .doc("details_document").set({
        suggested_followup_date: dialogState['suggested_followup_date'],
      }, {merge: true});

      temp_sd['suggested_followup_date'] = dialogState['suggested_followup_date'];
    }

    if(dialogState.hasOwnProperty("follow_up_notes")) {
      db.collection("consults").doc(props.location.state.caseData.id).collection("consult_details")
      .doc("details_document").set({
        follow_up_notes: dialogState['follow_up_notes'],
      }, {merge: true});

      temp_sd['follow_up_notes'] = dialogState['follow_up_notes'];
    }

    setSessionData(temp_sd);
    var imageUploadPromises = [];

    previewImages.forEach(async (image) => {
      imageUploadPromises.push(storageRef.child("consults").child(props.location.state.caseData.id)
        .child(image.name)
        .putString(image.data, "data_url"));
    });

    Promise.all(imageUploadPromises).then((values) => {
      values.forEach((snapshot) => {
        snapshot.ref.getDownloadURL().then(url => {
          db.collection("consults").doc(props.location.state.caseData.id).collection("consult_details")
          .doc("details_document").update({
            prescription_photos: firebase.firestore.FieldValue.arrayUnion(
              {
                file_name: snapshot.ref.name,
                file_path: url
              }),
          });
          let temp_sd = sessionData;
          if(!temp_sd.prescription_photos) {
            temp_sd['prescription_photos'] = [];
          }
          temp_sd.prescription_photos.push({
            file_name: snapshot.ref.name,
            file_path: url
          });
          setSessionData(temp_sd);
        });
      });
      setUploadLoader(false);
    });
    setPreviewImages([]);
  };

  const renderImagePreview = () => {
    var images = previewImages.map(image => image.data);

    if(sessionData.prescription_photos) {
      sessionData.prescription_photos.forEach((photo) => {
        images = [...images, photo.file_path]
      })
    }

    if(images.length < 0) {
      return (null);
    }

    let previewDiv = [];
    images.forEach((image) => {
      previewDiv.push(<img style={{maxWidth: "170px", borderRadius:"6px", marginLeft: "10px"}} src={image} />);
    })
    return(previewDiv);
  };

  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () =>{
      if(reader.readyState === 2){
        setPreviewImages([...previewImages, {
          name: inputRef.current.files[0].name,
          data: reader.result
        }]);
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };

  const uploadImage = () => {
    inputRef.current.click();
  };

  const openImage = (image) => {
    setSelectedImage(image);
    image_viewerRef.current.style.display = "block";
  };

  const closeImage = () => {
    image_viewerRef.current.style.display = "none";
  }

  const deleteImage = (photo_basket) => {
    sessionData[photo_basket].forEach((item, index, object) => {
      if (item.file_name === selectedImage.file_name) {
        object.splice(index, 1);
      }
    });

    var update = {};
    update[photo_basket] = sessionData[photo_basket];

    setSessionData({...sessionData, update});

    db
    .collection("consults")
    .doc(props.location.state.caseData.id)
    .collection("consult_details")
    .doc("details_document")
    .set(update, {merge: true})
    .then(() => {
      storageRef.child("consults")
      .child(props.location.state.caseData.id)
      .child(selectedImage.file_name)
      .delete();
    })

    closeImage();
  }

  const handleAllowVideoChange = (event) => {
    setAllowVideo(event.target.checked);
  }

  useEffect(() => {
    db
    .collection("consults")
    .doc(props.location.state.caseData.id)
    .collection("consult_details")
    .doc("details_document")
    .get().then((doc) => {
      setSessionData(doc.data());
    });

    if(props.location.state.caseData.allow_video == "Yes") {
      setAllowVideo(true);
    }

    let videoCallDocument = "main";
    if (props.location.state.caseData.data.status == "FOLLOW UP PERIOD") {
      videoCallDocument = "follow_up";
    }

    db
    .collection("consults")
    .doc(props.location.state.caseData.id)
    .collection("video_call")
    .doc(videoCallDocument)
    .get().then((doc) => {
      setVideoCallData(doc.data());
    });

    db.collection("users")
    .doc(props.location.state.caseData.data.user_id)
    .collection("pets")
    .doc(props.location.state.caseData.data.pet_id)
    .get()
    .then((doc) => {
      setPetDetails(doc.data());
    });
  }, []);

  const handleDialogDiagnosisValueChange = (e) => {
    dialogState["diagnosis"] = e.target.value;
    setDialogState(dialogState);
  }

  const handleDialogEmergencyValueChange = (e) => {
    dialogState["emergency"] = e.target.checked;
    setPetDetailsEmergencyCheckBox(e.target.checked);
    setDialogState(dialogState);
  }

  const handleDialogRecommendationsValueChange = (e) => {
    dialogState["recommendations"] = e.target.value;
    setDialogState(dialogState);
  }

  const handleDialogPrescriptionTextValueChange = (e) => {
    dialogState["prescription_text"] = e.target.value;
    setDialogState(dialogState);
  }

  const handleDialogFollowUpDateValueChange = (date) => {
    dialogState["suggested_followup_date"] = date;
    setDialogState(dialogState);
  }

  const handleDialogFollowUpNotesValueChange = (e) => {
    dialogState["follow_up_notes"] = e.target.value;
    setDialogState(dialogState);
  }

  const markComplete = () => {
    props.location.state.caseData.data.status = "FOLLOW UP PERIOD";
    db
    .collection("consults")
    .doc(props.location.state.caseData.id)
    .set({
      status: "FOLLOW UP PERIOD"
    }, {merge: true});
    markCompletedDialogHandleClose();
  }

  const onMarkCompletedClick = () => {
    if(
      (!sessionData.diagnosis || sessionData.diagnosis == "") &&
      (!sessionData.recommendations || sessionData.recommendations == "") &&
      (!sessionData.prescription_text || sessionData.prescription_text == "") &&
      (!sessionData.suggested_followup_date)
    ) {
      window.alert("Doctor's notes have not been added!");
    } else {
      markCompletedDialogHandleOpen();
    }
  }

  const changeDateFormat = (dateStr) => {
    dateStr = dateStr.split("-");
    return `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`
  }

  const renderDoctorNotes = () => {
    if(sessionData.diagnosis || sessionData.recommendations || sessionData.prescription_text || sessionData.suggested_followup_date || sessionData.prescription_photos) {
      return (
        <div className={styles.pet_data_session_doctors_notes_container} >
          <div className={styles.doctors_notes_edit_button_div} onClick={handleClickOpen}>
            { phoneNumber == props.location.state.caseData.data.vet_id?
              (
                <>
                <EditIcon style={{marginRight: "5px"}}/>
                Edit
                </>
              ):(null)
            }
          </div>
          <span className={styles.pet_data_session_media_span}>Diagnosis</span> <br/>
          {sessionData.diagnosis} <br/><br/>
          {
            sessionData.emergency?
            (
              <div className={styles.pet_data_session_info_emergency_container}>
                <img src={EmergencyBulb} className={styles.pet_data_session_info_emergency_bulb_img}/>
                <div className={styles.pet_data_session_info_emergency_text}>
                  Asked the patient to go to a physical vet as soon as possible.
                </div>
              </div>
            ):(null)
          }
          <span className={styles.pet_data_session_media_span}>Recommendations</span> <br/>
          {sessionData.recommendations} <br/> <br/>
          <span className={styles.pet_data_session_media_span}>Prescription</span> <br/>
          {sessionData.prescription_text}
          <div className={styles.pet_data_session_media_div}>
            <div className={styles.images}>
            {
              sessionData.prescription_photos?.map(prescription_photo => {
                return (
                <div className={styles.pet_data_session_single_image}>
                  <img src={prescription_photo?.file_path} width="300" height="200" onClick={() => openImage(prescription_photo)}/>
                </div>
                  )
              })
            }
            </div>
            <div className={styles.image_viewer} ref={image_viewerRef} >
              <span className={styles.image_viewer_close} onClick={closeImage}>&times;</span>
              {
                phoneNumber == props.location.state.caseData.data.vet_id?
                (
                  <span className={styles.image_viewer_delete} onClick={() => deleteImage("prescription_photos")}>Delete</span>
                ):(null)
              }
              <img className={styles.modal_content} src={selectedImage?.file_path}/>
            </div>
          </div>
          <br/>
          <span className={styles.pet_data_session_media_span}>Suggested Follow up Date</span> <br/>
          {sessionData.suggested_followup_date? (
            sessionData.suggested_followup_date.toDate? (
              changeDateFormat(sessionData.suggested_followup_date?.toDate?.().toISOString().substr(0,10))
            ):(
              sessionData.suggested_followup_date.getDate?.() + "-" +
              (sessionData.suggested_followup_date.getMonth?.() + 1) + "-" +
              sessionData.suggested_followup_date.getFullYear?.()
            )
          ):(null)
          }
          <br/><br/>
          <div className={styles.pet_data_session_follow_up_note_container}>
            <div>
              <span className={styles.pet_data_session_media_span}>Follow-up Notes</span> <br/>
              {sessionData.follow_up_notes}
            </div>
            {/* <div className={styles.pet_data_session_follow_up_note_add_button} onClick={onMarkCompletedClick}>
              Add follow-up notes
            </div> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.doctor_notes_container}>
          <span className={styles.doctor_notes_no_description}>No description added yet</span>  <br/><br/>
          { phoneNumber == props.location.state.caseData.data.vet_id?
              (
                <div className={styles.doctor_note_add_button} onClick={handleClickOpen}>
                  Add notes
                </div>
              ):(
                null
              )
            }

        </div>
      )
    }
  }

    return (
        <>
        <div className={styles.main_container} >
          <div className={styles.backbutton_consultid_container}>
            {
              props.location.state.isPastConsult?
              (
                <Button onClick={() => history.goBack()}>
                  <div className={styles.back_button_container} style={{fontWeight:"bold"}} >
                    <ArrowBackIcon />  &emsp; Back
                  </div>
                </Button>
              ):(
                <Button onClick={() => history.push("/")}>
                  <div className={styles.back_button_container} style={{fontWeight:"bold"}} >
                    <ArrowBackIcon />  &emsp; Home
                  </div>
                </Button>
              )
            }
            <div className={styles.consult_id_text}>
              Consult ID - {props.location.state.caseData.data.consult_id_hash}
            </div>
          </div>
          <div className={styles.pet_container} >
            <div className={styles.pet_details_container} >
              <Paper elevation={3} className={styles.pet_details_paper}>
                <div className={styles.pet_details_paper_container}>
                  <div className={styles.pet_details_photo}>
                    <img
                      src={resolveToDefaultPhoto(props.location.state.caseData.data.pet_photo, "pet")}
                      className={styles.pet_details_pet_pic}
                      onClick={() => history.push({
                        pathname: "/pet",
                        state: {
                            userId: props.location.state.caseData.data.user_id,
                            userName: props.location.state.caseData.data.user_name,
                            userPhoto: props.location.state.caseData.data.user_photo,
                            petId: props.location.state.caseData.data.pet_id,
                            petPhoto: props.location.state.caseData.data.pet_photo,
                            vetName: props.location.state.caseData.data.vet_name
                        }
                        })}
                      />
                    <img src={resolveToDefaultPhoto(props.location.state.caseData.data.user_photo, "parent")} className={styles.pet_details_parent_pic} />
                  </div>

                  <div className={styles.pet_details_name}>
                    <span className={styles.pet_details_name_span}>{props.location.state.caseData.data.pet_name}, {props.location.state.caseData.data.user_name}</span> <br/>
                  </div>

                  <div className={styles.pet_details_requested}>
                    Requested on {props.location.state.caseData.data.created_at?.toDate?.().toLocaleString("en-IN", {
                                          weekday: "short",
                                          day:    "numeric",
                                          month:  "short",
                                          year:   "numeric",
                                          hour:   "2-digit",
                                          minute: "2-digit",
                                          timeZone: "Asia/Kolkata"
                                      })}
                  </div>
                  {
                    props.location.state.isCompleted ?
                    (
                      <div style={{ backgroundColor: "#E9F4E6", color:"#209304" }}>
                        COMPLETED
                      </div>
                    ):(
                      <>
                        <div style={{
                          backgroundColor: statusColor(
                            props.location.state.caseData.data.status
                          ).background,
                          color: statusColor(
                            props.location.state.caseData.data.status
                          ).text }}>
                        {props.location.state.caseData.data.status}
                        </div>
                        {/* <div className={styles.pet_details_follow_up}>
                          Follow up: {props.location.state.caseData.data.ended_at?.toDate?.().toISOString().substr(0,10)} {props.location.state.caseData.data.ended_at?.toDate?.().toISOString().substr(11,5)}
                        </div> */}
                      </>
                    )
                  }

                </div>
                {
                  props.location.state.isCompleted ?
                  (null):(
                    videoCallData.call_time &&
                    videoCallData.meet_link &&
                    videoCallData.call_time !== "" &&
                    videoCallData.meet_link !== "" ? (
                      <div className={styles.pet_details_video_call_container}>
                        <div className={styles.pet_details_video_call_time}>
                          Video call scheduled for
                          <b style={{marginLeft: "2px"}}>
                            {videoCallData.call_time?.toDate?.().toLocaleString("en-IN", {
                                                  weekday: "short",
                                                  day:    "numeric",
                                                  month:  "short",
                                                  year:   "numeric",
                                                  hour:   "2-digit",
                                                  minute: "2-digit",
                                                  timeZone: "Asia/Kolkata" })}
                          </b>
                        </div>
                        <div className={styles.pet_details_video_call_start_call_div}>
                          <a style={{display: "table-cell", textDecoration: "none"}} href={videoCallData.meet_link} target="_blank">
                            <div style={{cursor: "pointer", display: "flex", alignContent: "center", marginLeft: "2px"}}>
                              <VideocamIcon /> <span>Start call</span>
                            </div>
                          </a>
                          <div className={styles.pet_details_video_call_link_share}>
                          <CopyToClipboard text={videoCallData.meet_link}
                          onCopy={() => window.alert("Link copied to clipboard")}>
                            <LinkIcon  />
                          </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    ):(
                      null
                    )
                  )
                }
              </Paper>
              {
                props.location.state.isCompleted ?
                (<br/>):(
                  phoneNumber == props.location.state.caseData.data.vet_id &&
                  (props.location.state.caseData.data.status != "FOLLOW UP PERIOD" && props.location.state.caseData.data.status != "COMPLETED")?
                    (
                      <div className={styles.case_completed_button_style} onClick={onMarkCompletedClick}>
                        Mark consult as complete
                      </div>
                    ):(<br/>)
                )
              }
            </div>
            <div className={styles.pet_data_container} >
              <Paper elevation={3} className={styles.pet_data_paper}>

                    <AppBar position="static">
                      <StyledTabs value={value} onChange={handleChange} centered variant="fullWidth" className={styles.pet_data_styled_tabs} >
                        <StyledTab label="Consult Info" style={{fontWeight:"bold"}}/>
                        <StyledTab label="Doctor's Notes" style={{fontWeight:"bold"}}/>
                      </StyledTabs>
                    </AppBar>
                    <div className={styles.pet_data_paper_div}>
                    <TabPanel value={value} index={0}>
                      <div className={styles.pet_data_session_info_pet_data_container} >
                        <img src={resolveToDefaultPhoto(props.location.state.caseData.data.pet_photo, "pet")} className={styles.pet_data_session_info_img} />
                        <div className={styles.pet_data_session_info_name_div}>
                          <span className={styles.pet_data_session_info_name_name_span}>{props.location.state.caseData.data.pet_name}</span> <br/>
                          <span className={styles.pet_data_session_info_name_age_span}>{getAge(petDetails.date_of_birth)} ·  {petDetails.breed} . {petDetails.species} · {petDetails.gender}</span>
                        </div>
                      </div>
                      <div className={styles.pet_data_session_info_name_issue_div}>
                        <span className={styles.pet_data_session_info_name_issue_span}>Issue</span> <br/>
                        <span className={styles.pet_data_session_info_name_issue_title}>{props.location.state.caseData.data.issue_type}</span>
                      </div>
                      <div className={styles.pet_data_session_additional_details_div}>
                        <span className={styles.pet_data_session_additional_details_name}>Additional details</span> <br/>
                        <span className={styles.pet_data_session_additional_details_title}>{sessionData.issue_desc}</span>
                      </div>
                      <div className={styles.pet_data_session_media_div}>
                        <span className={styles.pet_data_session_media_span}>Media</span> <br/>
                        <div className={styles.images}>
                          {
                            sessionData.issue_media?.map((media) => {
                              if(checkImage(media.file_name)) {
                                return(
                                  <div className={styles.pet_data_session_single_image}>
                                    <img src={media?.file_path} width="300" height="200" onClick={() => openImage(media)}/>
                                  </div>
                                )
                              } else {
                                return(
                                  <div className={styles.pet_data_session_single_video}>
                                    <video controls width="300" height="200">
                                      <source src={media.file_path}/>
                                    </video>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                        <div className={styles.image_viewer} ref={image_viewerRef} >
                          <span className={styles.image_viewer_close} onClick={closeImage}>&times;</span>
                          <img className={styles.modal_content} src={selectedImage?.file_path}/>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {
                        uploadLoader?
                        (
                          <h2>Uploading notes, please wait...</h2>
                        ):(
                          renderDoctorNotes()
                        )
                      }
            <Dialog open={open} onClose={handleClose} fullWidth>
            <div className={styles.doctor_note_dialog_div}>
              <DialogActions>
                <div className={styles.doctor_note_dialog_data_div}>
                  <DialogTitle id="form-dialog-title">Add your notes</DialogTitle>
                  <div className={styles.doctors_notes_dialog_control_box}>
                    <div className={styles.doctors_notes_cancel_button} onClick={handleClose}>
                      Cancel
                    </div>
                    <div className={styles.doctors_notes_save_button} onClick={handleSave}>
                      Save
                    </div>
                  </div>
                </div>
              </DialogActions>
            </div>
            <DialogContent>
              <DialogContentText>
                 Diagnosis
              </DialogContentText>
              <TextField
                margin="dense"
                id="diagnosis"
                label="Enter diagnosis here"
                type="text"
                multiline="true"
                defaultValue={sessionData.diagnosis}
                fullWidth
                onChange={handleDialogDiagnosisValueChange}
              />

              <br/> <br/>
              <div style={{display: "flex", alignItems: "center"}}>
              <Checkbox
                checked={petDetailsEmergencyCheckBox}
                onChange={handleDialogEmergencyValueChange}
                color="primary"
              />
              <DialogContentText style={{marginTop: "15px"}}>
                Ask the patient to go to a physical vet as soon as possible.
              </DialogContentText>
              </div>

              <br/>
              <DialogContentText>
                Recommendations
              </DialogContentText>
              <TextField
                margin="dense"
                multiline="true"
                id="recommendations"
                label="Enter recommendations here"
                type="text"
                defaultValue={sessionData.recommendations}
                fullWidth
                onChange={handleDialogRecommendationsValueChange}
              />


              <br/> <br/> <br/>
              <DialogContentText>
                Prescription
              </DialogContentText>
              <TextField
                margin="dense"
                multiline="true"
                id="prescription"
                label="Enter prescription here"
                type="text"
                defaultValue={sessionData.prescription_text}
                fullWidth
                onChange={handleDialogPrescriptionTextValueChange}
              />


              <br/> <br/> <br/>
              <DialogContentText>
                Add photos of the prescription
              </DialogContentText>

              <DialogContent>
                <div className={styles.add_prescription_photo_div}>
                  <div className={styles.add_prescription_photo_button_div} onClick={uploadImage}>
                    <input type="file" accept="image/*" ref={inputRef} className={styles.image_upload_button} onChange={imageHandler} />
                    <img src={InputButton} className={styles.add_prescription_photo_button_image}/>
                  </div>
                  {renderImagePreview()}
                </div>
              </DialogContent>

              <br/> <br/>
              <DialogContentText>
                Suggested follow-up date
              </DialogContentText>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  value={dialogState.suggested_followup_date}
                  format="dd/MM/yyyy"
                  onChange={date => handleDialogFollowUpDateValueChange(date)}
                />
              </MuiPickersUtilsProvider>

              <br/> <br/> <br/>
              <DialogContentText>
                Follow-up notes
              </DialogContentText>
              <TextField
                margin="dense"
                multiline="true"
                id="follow_up_notes"
                label="Enter follow-up notes here"
                type="text"
                defaultValue={sessionData.follow_up_notes}
                fullWidth
                onChange={handleDialogFollowUpNotesValueChange}
              />
              </DialogContent>
              <br/><br/>
              </Dialog>
                    </TabPanel>
                  </div>
              </Paper>
            </div>
          </div>
          <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={markCompletedDialogOpen}>
              <div className={styles.mark_consult_complete_dialog_popup_container}>
                  <div className={styles.mark_consult_complete_dialog_text}>
                      Are you sure?
                  </div>
                  <div className={styles.mark_consult_complete_dialog_button_container}>
                      <div className={styles.mark_consult_complete_dialog_button_yes_style} onClick={markComplete}>
                          Yes
                      </div>
                      <div className={styles.mark_consult_complete_dialog_button_no_style} onClick={markCompletedDialogHandleClose}>
                          No
                      </div>
                  </div>
              </div>
            </Dialog>
        </div>
        </>
    )
}
