import React, { Component, createContext } from "react";
import { auth as firebase_auth } from "../../firebase/firebase";
import { db } from "../../firebase/firebase";

export const AuthContext = createContext({ auth: null });

class AuthProvider extends Component {
  state = {
    auth: {}
  };

  componentDidMount = () => {
    this.setState({
      auth: JSON.parse(localStorage.getItem("userAuth")),
    });

    firebase_auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        db
        .collection("vets")
        .doc(userAuth.phoneNumber)
        .get()
        .then((doc) => {
          if (doc.exists) {
            localStorage.setItem('userAuth', JSON.stringify(userAuth));
            this.setState({
              auth: userAuth
            });
          } else {
            localStorage.removeItem('userAuth');
            this.setState(null);
          }
        });
      } else {
        localStorage.removeItem('userAuth');
        this.setState(null);
      }
    });
  };

  render() {
    return (
      <AuthContext.Provider value={this.state.auth}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
export default AuthProvider;