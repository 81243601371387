export const statusColor = (status) => {
    var color = {};
    switch(status) {
        case "IN PROGRESS":
            color["text"] = "#FF8029";
            color["background"] = "#FFF2EA";
            break; 
        case "COMPLETED":
            color["text"] = "#209304";
            color["background"] = "#E9F4E6";
            break; 
        default:
            color["text"] = "#E4AF11";
            color["background"] = "#FCF7E7";
            break; 
    }

    return color;
}