import React, { useContext, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
import SOS from "./Components/SOS/SOS";
import { Route, Switch } from "react-router-dom";
import VetProfile from "./Pages/VetProfile/VetProfile";
import PetProfile from "./Pages/PetProfile/PetProfile";
import SessionDetails from "./Pages/Session/SessionDetails";
import Auth from "./Pages/Auth/Auth";
import Home from './Pages/Home/Home';
import { AuthContext } from "./Pages/Auth/AuthContext";
import { isMobile } from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function VetApp() {
    const auth = useContext(AuthContext);
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

    return(
        auth ? (
            Object.keys(auth).length === 0 ? (
                <CircularProgress />
            ):(
                <Router>
                    <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    {/* <SOS /> */}
                    <Switch>
                        <Route exact path="/vet" component={VetProfile} />
                        <Route exact path="/pet" component={PetProfile} />
                        <Route exact path="/session" component={SessionDetails} />
                        <Route path="/" component={() => (<Home sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />)} />
                    </Switch>
                </Router>
            )
        ):(
            <Auth />
        )
    )
}
