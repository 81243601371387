/* export const checkImage = (url) => {
  const img = new Image();

  img.src = url;

  if (img.complete) {
    return true;
  } else {
    img.onload = () => {
      return true;
    };
    img.onerror = () => {
      return false;
    };
  }
} */

export const checkImage = (img_name) => {

  const img_ext = img_name.split(".")[1]

  if (img_ext == "jpg" || img_ext == "jpeg" || img_ext == "png" || img_ext == "gif" || img_ext == "tif" || img_ext == "tiff") {
    return true;
  } else {
    return false;
  }
}
