import React from "react";
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import { resolveToDefaultPhoto } from "../../../utils/resolveToDefaultPhoto";
import styles from "./ActiveCaseCard.module.css";
import { statusColor } from "../../../utils/statusColor";

function ActiveCaseCard({ caseData }) {
    const history = useHistory();

    return (
        <div onClick={() => history.push({
            pathname: "/session",
            state: {
                caseData: caseData,
            }
            })}
            className={styles.main_container}
        >
        <Paper elevation={3} >
            <div className={styles.card_container}>

                <div className={styles.photo_container} >
                    <img src={resolveToDefaultPhoto(caseData['data'].pet_photo, "pet")} className={styles.pet_photo}/>
                    <img src={resolveToDefaultPhoto(caseData['data'].user_photo, "parent")} className={styles.parent_photo} />
                </div>

                <div className={styles.name_time_div} >
                    <div className={styles.pet_parent_name_div}>
                        {caseData['data'].pet_name}, {caseData['data'].user_name}
                    </div>
                    <div>
                      {/*  Requested on {caseData['data'].created_at.toDate?.().toISOString().substr(0,10)} {caseData['data'].created_at.toDate?.().toISOString().substr(11,5)} */}
                        Requested on {caseData['data'].created_at?.toDate?.().toLocaleString("en-IN", {
                                            weekday: "short",
                                            day:    "numeric",
                                            month:  "short",
                                            year:   "numeric",
                                            hour:   "2-digit",
                                            minute: "2-digit",
                                            timeZone: "Asia/Kolkata"
                                        })}
                    </div>
                </div>

                <div className={styles.issue_div} >
                    <div className={styles.issue_title_div}>
                        Issue
                    </div>
                    <div>
                        {caseData['data'].issue_type}
                    </div>
                </div>

                <div className={styles.status_div} >
                    <div className={styles.status_title_div}  style={{ backgroundColor: statusColor(caseData["data"].status).background, color:statusColor(caseData["data"].status).text }}>
                        {caseData["data"].status}
                    </div>
                    <div>
                      {/*  {caseData['data'].ended_at?.toDate?.().toISOString().substr(0,10)} {caseData['data'].ended_at?.toDate?.().toISOString().substr(11,5)} */}
                      {/*}  {caseData['data'].ended_at?.toDate?.().toLocaleString("en-IN", {
                                            weekday: "short",
                                            day:    "numeric",
                                            month:  "short",
                                            year:   "numeric",
                                            hour:   "2-digit",
                                            minute: "2-digit",
                                            timeZone: "Asia/Kolkata"
                                        })} */}
                    </div>
                </div>
            </div>
        </Paper>
        </div>
    )
}

export default ActiveCaseCard;
