  export const getAge = (dateString)  => {
      if (!dateString) {
        return "";
      }

      // var today = new Date();
      var birthDate = new Date(`${dateString.split("-")[2]}-${dateString.split("-")[1]}-${dateString.split("-")[0]}`);
      var diffDate = new Date(new Date() - birthDate);
      return ((diffDate.toISOString().slice(0, 4) - 1970) + "y " + diffDate.getMonth() + "m ");
    }
