import React, { useEffect, useState, useContext } from "react";
import ActiveCaseCard from "./ActiveCaseCard/ActiveCaseCard";
import CompletedCaseCard from "./CompletedCaseCard/CompletedCaseCard";
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import EmptyActiveImage from "../../Assets/no_active_image.png";
import EmptyCompletedImage from "../../Assets/no_completed_image.png";
import styles from "./CaseList.module.css";
import { db } from "../../firebase/firebase";
import { AuthContext } from "../Auth/AuthContext";

export default function CaseList(props) {
  const { phoneNumber } = useContext(AuthContext);
  const [consults, setConsults] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [available, setAvailable] = useState(false);

  useEffect(() => {
    setConsults(props.consults);
    setLoading(false);
  }, [props.consults]);

  // useEffect(() => {
  //   db
  //     .collection("vets")
  //     .doc(phoneNumber)
  //     .get()
  //     .then((doc) => {
  //       if (doc.data()) {
  //         setAvailable(doc.data().available);
  //       }
  //     });
  // }, []);

  // const handleChange = () => {
  //   db
  //     .collection("vets")
  //     .doc(phoneNumber)
  //     .set({
  //       available: !available
  //     }, {merge: true})
  //     .then(() => {
  //       setAvailable(!available);
  //     });
  // }

  var renderCases = () => {
    let view = [];
    switch(props.type) {
      case "active":
        if(Object.keys(consults).length < 1) {
          return (
            <div className={styles.empty_img_container}>
              <img src={EmptyActiveImage} className={styles.empty_img}/>
              <span className={styles.empty_img_container_text}>There are no active consults.</span>
            </div>
          );
        }

        consults.forEach((c, i) => {
          view.push(<ActiveCaseCard caseData={c} key={i}/>)
        });
        return <div> { view } </div>;
      default:
        if(consults.length < 1) {
          return (
            <div className={styles.empty_img_container}>
              <img src={EmptyCompletedImage} className={styles.empty_img}/>
              <span className={styles.empty_img_container_text}>There are no completed consults.</span>
            </div>
          );
        }
        
        consults.forEach?.((c, i) => {
          view.push(<CompletedCaseCard caseData={c} key={i}/>)
        });
        return <div> { view } </div>;
    }
  };

  return (
    <div className={styles.caselist_section}>
      <div className={styles.caselist_container}>
        {
          loading ? 
          (
            <div>
              loading...
            </div>
          ) :
          (
            renderCases()
          )
        }
      </div>
      {/* <div className={styles.available_section}>
        <Paper elevation={3} >
              <div className={styles.available_container}>
                  <div className={styles.available_text_container}>
                      <div className={styles.available_text_container_available}>
                            Available
                      </div>
                      <div>
                          Current status: {
                            available? ("Available") : ("Unavailable")
                          }
                      </div>
                  </div>
                  <div className={styles.available_switch_container}>
                      <Switch 
                        color="primary" 
                        onChange={handleChange}
                        checked={available}
                      />  
                  </div>
              </div>
        </Paper>
      </div> */}
    </div>
  );
}