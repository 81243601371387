import React, { useState } from "react";
import Fab from '@material-ui/core/Fab';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import Dialog from '@material-ui/core/Dialog';
import SupportImage from "../../Assets/support.png";
import styles from "./SOS.module.css";
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

function SOS() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>
        <Fab color="primary" size="large" style={{position:"fixed", bottom:"30px", right: "50px", zIndex: "100"}} onClick={handleClickOpen}>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center",}}>
                <ChatBubbleOutlineRoundedIcon style={{width:"30%", color: "white"}}/>
                <span style={{fontSize:"80%", color: "white"}}>
                    Chat
                </span>
            </div>
        </Fab>
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <div className={styles.popup_container}>
                <img src={SupportImage} className={styles.support_img}/>
                <div className={styles.text}>
                    Our support team will help you with your query.
                </div>
                <div className={styles.button_container}>
                    <div className={styles.button_style}>
                        <ChatBubbleOutlineRoundedIcon style={{marginRight: "5px"}}/>
                        Chat  
                    </div>
                    <div className={styles.button_style}>
                        <CallOutlinedIcon style={{marginRight: "5px"}}/>
                        Call  
                    </div>
                </div>
                <div className={styles.cancel} onClick={handleClose}>
                    Cancel
                </div>
            </div>
        </Dialog>
        </>
    )
}

export default SOS;