import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase";
import { auth as firebase_auth } from '../../firebase/firebase';
import styles from "./Auth.module.css";
import stackedLogo from "../../Assets/stacked_logo.png";

const Auth = () => {
  const uiConfig = {
    signInSuccessUrl: "/",
    signInOptions: [{
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      defaultCountry: 'IN',
      whitelistedCountries: ['IN'],
      recaptchaParameters: {
        type: 'image', // 'audio'
        size: 'invisible', // 'invisible' or 'compact'
        badge: 'bottomright' //' bottomright' or 'inline' applies to invisible.
      },
    }],
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.auth_container}>
        <div className={styles.logo}>
          <img src={stackedLogo} />
        </div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase_auth} className={styles.login}/>
      </div>
      <div className={styles.footer}>
        By signing in, I agree to Wagr’s Terms of Use & Privacy Policy
      </div>
    </div>
  );
}

export default Auth;
